<template>
    <el-dialog v-if="m" :title="title" :visible.sync="isShow" width="550px" top="10vh" :append-to-body="true" :destroy-on-close="true" :close-on-click-modal="false" custom-class="full-dialog">
        <div class="vue-box">
            <!-- 参数栏 -->
            <div class="c-panel">
                <el-form size="mini" v-if="m" ref="ruleForm" :rules="rules" :model="m" class="demo-ruleForm" label-width="120px">
                    <el-form-item label="项目：" prop="projectName">
                        <span v-if="m.project">{{m.project.name}}</span>
                    </el-form-item>

                    <el-form-item label="任务名称：" prop="name">
                        <el-input v-model="m.name"></el-input>
                    </el-form-item>

                    <el-form-item label="研发负责人：" prop="userId">
                        <el-select v-model="m.userId" filterable>
                            <el-option value="">请选择</el-option>
                            <el-option v-for="item in users" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <el-tag :key="tag" v-for="tag in userTags" closable :disable-transitions="false" @close="handleClose(tag)" style="margin-left: 10px;">
                            {{tag}}
                        </el-tag>
                    </el-form-item>
                    <el-form-item label="类型">
                        <input-enum enumName="implementCategoryEnum" v-model="m.category"></input-enum>
                    </el-form-item>
                    <el-form-item label="计划完成时间：" prop="planDate">
                        <el-date-picker v-model="m.planDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="计划完成时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="实际完成时间：" prop="finishDate">
                        <el-date-picker v-model="m.finishDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="实际完成时间"></el-date-picker>
                    </el-form-item>
                    <el-form-item label="状态">
                        <input-enum enumName="taskStateEnum" v-model="m.state"></input-enum>
                    </el-form-item>
                    <el-form-item>
                        <span class="c-label">&emsp;</span>
                        <el-button type="primary" icon="el-icon-plus" size="small" @click="ok('ruleForm')">确定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入任务名称",
                        trigger: "blur"
                    }
                ],
                projectId: [],
                userId: [],
                users: [],
                category: [],
                planDate: [],
                finishDate: [],
                state: []
            },
            fileList: [],
            userTags: [],
            users:[]
        };
    },
    methods: {
        open: function(data) {
            this.isShow = true;
            if (data.id) {
                this.title = "修改项目实施清单";
                this.m = data;
                this.userTags = this.m.users.split(",");
            } else {
                this.m = {
                    name: "",
                    projectId: data.project.id,
                    userId: 0,
                    users: "",
                    category: 0,
                    planDate: "",
                    finishDate: "",
                    state: 0
                };
                this.m.project = data.project;

                this.title = "添加 项目实施清单";
            }
        },

        //提交项目实施清单信息
        ok: function(formName) {
            this.m.users = this.userTags.join(",");
            this.users.forEach(item => {
                if (item.id == this.m.userId) {
                    if (this.m.users) {
                        if (this.m.users.indexOf(item.name) == -1) {
                            this.m.users = this.m.users + "," + item.name;
                        }
                    } else {
                        this.m.users = item.name;
                    }
                }
            });

            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.sa.post("/implement/save", this.m).then(res => {
                        console.log(res);
                        this.$parent.f5();
                        this.isShow = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        handleClose(tag) {
            this.userTags.splice(this.userTags.indexOf(tag), 1);
        }
    },
    created() {
      this.sa.get("/user/all").then(res => {
                this.users = res.data;
            });
    }
};
</script>